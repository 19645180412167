@import "tailwind.css";
@import "loader.css";
@import "~react-toastify/dist/ReactToastify.min.css";


html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

#resource-links h1:first-child {
  display: flex;
  align-items: center;
}

#resource-links h1:first-child img {
  margin-right: 10px;
}

#resource-links h1, #resource-links h2 {
  margin-bottom: 1em;
  margin-top: 1em;
}

#resource-links h3 a, #resource-links h1, #resource-links a[href="#-android"] {
  display: flex;
  align-items: center;
}

#resource-links h3 a img, #resource-links h1 img, #resource-links a[href="#-android"] img {
  margin-right: 10px;
}

#resource-links .toc {
  position: fixed;
  width: 300px;
  border: solid 1px #f1f1f1;
  top: 100px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  background-color: #f1f1f1;
  padding: 10px;
}

#resource-links .toc ol, #resource-links .toc ol li {
  margin-bottom: 0;
  margin-top: 0;
}

#resource-links .toc ol li a {
  font-weight: normal;
  font-size: 0.9em;
}

@media (max-width: 1023px) {
  #resource-links .toc {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  #resource-links .toc {
    right: 20px;
  }
}

@media (min-width: 1440px) {
  #resource-links .toc {
    right: 95px;
  }
}
